.modal {
    &__header {
        width: 100%;
        border-bottom: 1px solid $border-color;
        text-align: center;
        text-transform: uppercase;
        padding: 20px 0 10px;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 10px;
        padding: 40px 20px 20px;
    }

    &__actions {
        width: 100%;
        padding: 10px 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        padding: 2px 5px;
        line-height: 20px;
        right: 8px;
        top: 5px;
        font-size: 18px;
        opacity: 1;
        color: $text-color;
        outline: none;
    }

    &__cancel {
        background-color: $button-disabled;

        &:hover {
            background-color: $light-gray;
        }
    }

    &__spinner-button {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__error-message {
        color: crimson;
    }
}

.popup-content {
    border-radius: 15px;
}
