.default-input {
    $self: &;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;
    margin: 0;
    gap: 10px;
    line-height: 150%;
    border: none;
    border-bottom: 1px solid $border-color;
    color: $text-color;
    background-color: transparent;

    &:disabled {
        border-bottom: 1px solid $border-disabled;
        color: $border-disabled;
    }

    &:active {
        border-bottom: 1px solid $border-active;
    }

    &:focus {
        border-bottom: 1px solid $border-active;
        outline: none;
    }

    &__full-width {
        width: 100%;
    }

    &__textarea {
        border: none;
        border-bottom: 1px solid $border-color;
    }
}
