@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/HelveticaNeue/HelveticaNeue-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 500;
    src: url('../../assets/fonts/HelveticaNeue/HelveticaNeue-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/HelveticaNeue/HelveticaNeue-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: bold;
    font-weight: 900;
    src: url('../../assets/fonts/HelveticaNeue/HelveticaNeue-Bold.otf') format('opentype');
}
