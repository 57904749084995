.pagination {
    $self: &;
    margin: 20px 0;

    &__list {
        display: flex;
        gap: 10px;
    }

    &__page-number {
        border-radius: 30px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        transition: all 0.3s ease-in-out;

        &__prev {
            background: none !important;
            font-size: 22px;

            &:hover {
                cursor: pointer;
                color: $primary-orange;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $primary-orange;
            color: $white;
        }
    }

    &__active-page {
        border: 2px solid $primary-orange;
    }
}
