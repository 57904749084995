.select-manufacturers {
    &__brands-wrapper {
        padding: 20px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        overflow-y: auto;
        margin: 20px 0;

        @media (min-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__alpha-state {
        border-top: 1px solid #e3e1e1;
        padding: 20px 5%;
        text-align: justify;
    }

    &__brand-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 5px 10px;
        box-shadow: 0px 0px 10px 1px #e3e1e1;

        &--brand-logo {
            height: 50px;
            width: 100px;
            object-fit: contain;
            padding-right: 10px;
            margin: 0;
        }

        &--select-brand-checkbox {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 10px;
            gap: 10px;
        }

        label {
            font-size: 16px;
            margin: 0;
        }

        &:hover {
            label {
                cursor: pointer;
            }
        }
    }

    &__save {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
