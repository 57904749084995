.user-management {
    $self: &;
    background-color: $white;
    padding: 20px;
    overflow: auto;

    &__user-table {
        display: flex;
        justify-content: center;
        padding: 0 20px;
        width: 100%;

        td {
            vertical-align: middle;
        }

        &--operations {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            button {
                width: 180px;
            }
        }

        &--list {
            list-style: disc;
        }
    }

    &__create-user {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        hr {
            width: 100%;
        }

        &--form {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 90%;
            gap: 30px;
            align-items: flex-end;
            padding-bottom: 30px;
        }

        &--fields {
            width: 100%;
            display: flex;
            gap: 30px;
        }

        &--roles {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &--input-field {
            width: 90%;
            display: flex;
            flex-direction: column;
        }

        &--button {
            width: 180px;
        }
    }

    &__role-select {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px 20px;

        label {
            display: flex;
            margin-bottom: 0;
            align-items: flex-start;
        }

        input {
            margin: 5px 10px;
        }
    }

    &__role-select-modal {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 30px 20px 30px 40px;
    }

    &__user-created {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
            width: 100%;
            text-indent: 15px;
            text-align: start;
        }
    }

    &__alert-container {
        .Toastify__toast {
            font-family: 'Helvetica Neue';
            font-weight: 500;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.8), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        }

        .Toastify__toast-icon {
            width: 40px;
            margin-right: 20px;
        }

        &--toast-message {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__mfa-wrapper {
        width: 90%;
        margin: auto;
    }

    &__mfa-wrapper p {
        margin-bottom: 16px;
    }

    &__loader {
        height: 100px;
    }
}

.change-roles-modal-overlay {
    background-color: rgba(0, 0, 0, 0.08);
}
