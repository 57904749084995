.customer-view {
    $self: &;
    width: 100%;

    &__log-owner {
        color: $text-color;
        background-color: $background-gray;
        border-radius: 4px;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        padding: 2px 6px;
    }

    &__log-timestamp {
        color: #747474;
        margin-bottom: 0.5rem;
    }

    &__change-list {
        margin-bottom: 16px;
        list-style: disc;
        text-align: start;
    }

    &__sublist {
        p {
            margin-bottom: 5px !important;
        }
    }

    &__event {
        text-align: start;
    }

    &__log-header {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-bottom: 5px;

        p {
            margin: 0;
        }
    }
}
