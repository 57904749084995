.sidebar {
    $self: &;
    max-width: 90px;
    width: 100%;
    min-height: calc(100vh - 135px);
    transition: 0.3s ease-in-out;
    background: $white;
    border-right: 6px solid $sidebar-border;

    &:hover {
        max-width: 270px;
        align-items: flex-start;
        display: inline-block;

        #{$self}__list--link--title {
            display: block;
        }
    }

    &__list {
        width: 100%;
        padding: 0;
        list-style: none;

        &--item {
            display: flex;
            width: 100%;
            margin-left: 6px;

            &:hover {
                transition: background-color 0.3s ease-in-out;
            }
        }

        &--item-active {
            display: flex;
            width: 100%;
            margin-left: 6px;
            border-right: 6px solid $primary-orange;
            filter: brightness(0) saturate(100%) invert(59%) sepia(57%) saturate(6820%) hue-rotate(347deg) brightness(91%) contrast(97%);
        }

        &--link {
            width: 100%;
            padding: 15px 23px;
            justify-content: flex-start;
            gap: 20px;
            margin: 0;
            display: flex;
            align-items: center;
            color: $dark-text;
            font-size: 18px;

            &:hover {
                text-decoration: none;
                filter: brightness(0) saturate(100%) invert(59%) sepia(57%) saturate(6820%) hue-rotate(347deg) brightness(91%) contrast(97%);
            }

            .icon {
                width: 28px;
                filter: invert(9%) sepia(7%) saturate(9%) hue-rotate(314deg) brightness(101%) contrast(88%);
            }

            &--title {
                display: none;
                white-space: nowrap;
                animation: 0.2s fadeIn;
                animation-delay: 100ms;
                animation-fill-mode: both;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
