.loader {
    $self: &;
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__emulate-loader {
        display: inline-block;
        position: relative;
        width: 90px;
        height: 90px;
        transform: rotate(90deg);
        /* use this property for vertical, or delete for horizontal */

        div {
            display: inline-block;
            position: absolute;
            left: 8px;
            width: 11px;
            background: $white;
            animation: emulate-loader 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;

            &:nth-child(1) {
                background-color: $dark-orange-loader;
                left: 8px;
                animation-delay: -0.24s;
            }

            &:nth-child(2) {
                left: 32px;
                background-color: #5ac7cf;
                animation-delay: -0.12s;
            }

            &:nth-child(3) {
                background-color: $gray-loader;
                left: 56px;
                animation-delay: 0;
            }
        }
    }
}

@keyframes emulate-loader {
    0% {
        top: 8px;
        height: 90px;
    }

    50%,
    100% {
        top: 24px;
        height: 45px;
    }
}
