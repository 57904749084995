.devices-health {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    &__back-icon {
        cursor: pointer;
    }

    &__header {
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;

        &-main {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        &-status {
            display: flex;
            gap: 5px;
        }

        &-dates {
            color: $text-color;
        }
    }

    &__content {
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 10px;

        &-checks {
            &-title {
                font-weight: bold;
                color: $text-color;
            }

            &-title--link {
                color: $primary-orange;
                cursor: pointer;
            }

            &-list {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                align-items: stretch;
                justify-content: flex-start;
            }

            &-list--no-border-bottom {
                border-bottom: none;
            }
        }
    }
}

.health-check-card {
    border-radius: 8px;
    padding: 16px;
    margin: 10px 0;
    background: $white;
    box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;
    font-family: Arial, sans-serif;
    max-width: 400px;

    &__title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid $border-color;
    }

    &__details {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        li {
            padding: 5px 0;
        }

        strong {
            color: $text-color;
            font-weight: bold;
        }
    }
}

.status-icon {
    font-size: 20px;
}

.status-text {
    font-weight: bold;

    &--critical {
        color: $critical-state;
    }

    &--warning {
        color: $warning-state;
    }

    &--healthy {
        color: $healthy-state;
    }
}

.status-icon--ok {
    color: $healthy-state;
}

.status-icon--not-ok {
    color: $critical-state;
}

.alert-card {
    flex: 1 1 calc(50% - 20px);
    min-width: 300px;
    background: $light-orange;
    border-left: 5px solid $primary-orange;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon {
        font-size: 24px;
        margin-right: 10px;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__message {
        font-weight: bold;
        margin: 0;
    }

    &__timestamp {
        font-size: 14px;
        color: $text-color;
    }
}

.no-alerts-fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-gray;
    box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    text-align: center;

    &__message {
        font-size: 16px;
        font-weight: bold;
        color: $text-color;
    }
}
