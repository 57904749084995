html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-weight: 500;
    color: $text-color;
}

#wrapper {
    display: flex;
    background-color: $background-gray;
    height: 100%;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $primary-orange;

    &:hover {
        color: $primary-orange;
    }
}

ul {
    list-style: none;
}

p {
    margin: 0;
}

button {
    border: 0;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    line-height: 150%;
}

input,
textarea {
    font-size: 14px;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: $background-gray;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-gray;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $background-gray;
}

.react-tabs__tab {
    transition: background-color, color, 0.2s ease-in-out;
    padding: 10px 12px;
    font-size: 20px;
    border: none;
    color: $text-color;
    font-weight: 500;
    bottom: 0;

    @media (max-width: 1024px) {
        font-size: 16px;
    }

    &:focus {
        &::after {
            background: none;
        }
    }

    &--selected {
        border: none;
        border-radius: 0;
        background-color: $white;
        color: $primary-orange;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.session-expired {
    z-index: 10;
    position: fixed;
    width: 100%;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: default;

    &__logo {
        width: 300px;
    }

    &__box {
        background-color: $primary-orange;
        padding: 20px 40px;
        border-radius: 10px;
        color: white;
        text-align: center;

        &--logout-button {
            color: $text-color;
            cursor: pointer;
        }
    }
}
