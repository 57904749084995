.battery-details {
    $self: &;

    &__two-card-row {
        display: flex;
        margin-bottom: 20px;
        gap: 20px;

        .card-wrapper {
            width: 50%;
        }
    }

    &__edit {
        h3 {
            margin: 0;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__table {
        margin-top: 30px;
        table-layout: fixed;
        width: 100%;

        thead {
            border-bottom: 1px solid $primary-orange;

            th {
                padding: 10px 25px;
            }
        }

        tr {
            animation: fadeAnimation 1s ease 0s 1 normal;

            &:hover {
                animation: fadeAnimation 1s ease 0s 1 normal;
                background-color: #edececd4;
            }
        }

        td,
        th {
            padding: 10px 10px 0 25px;
            border-right: 1px solid $primary-orange;

            &:last-child {
                border-right: none;
            }
        }
    }

    &__edit-modal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            margin-top: 15px;
        }
    }

    &__vizualization-container {
        display: flex;
        flex-direction: column;

        h3 {
            margin: 0;
        }

        &--title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 40px;
        }

        #{$self}__legend-container {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 25px;

            &--legend-item {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        #{$self}__forecast-list-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;

            &--scale {
                margin-top: 24px;
                margin-right: 5px;
                height: 220px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-right-style: solid;
                border-right-color: gray;
                border-right-width: 1px;
                text-align: right;
            }
        }

        #{$self}__delivered-list {
            list-style-type: none;
            display: flex;
            align-items: end;
            margin: 0;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0;
            width: 90%;
            overflow-x: scroll;

            li {
                text-align: center;
                justify-content: end;
                padding: 0;
                text-decoration: none;
                justify-content: end;
                align-items: end;
            }
        }

        #{$self}__delivered-item {
            position: relative;
            padding: 0;
            display: flex;
            flex-direction: column;

            &:hover {
                background-color: $tabs-background;
            }
        }

        #{$self}__delivered-item-bottom-text {
            padding: 5px;
            border-right-width: 1px;
            border-right-style: solid;
            border-color: $tabs-background;
        }
    }
}
