.breadcrumbs {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;

    &__last-link {
        font-weight: bold;
    }
}
