.layout {
    $self: &;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content-wrapper {
        width: 100%;
        overflow: hidden;
        background-color: $background-gray;

        &--box {
            padding: 15px;

            .collapsedSidebar {
                margin-top: 80px;
            }

            @media (min-width: 768px) {
                padding: 20px;
                min-height: 100%;
            }
        }
    }

    .footer {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        background-color: $white;
    }
}
