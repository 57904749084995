.customer-details {
    $self: &;
    width: 100%;

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 20px auto;
        font-size: 16px;
        font-weight: 500;

        &--search-customers {
            width: 270px;
            position: relative;

            .autocomplete__search-result-list {
                top: 100%;
            }
        }

        &--back-btn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: $primary-orange;
        }

        @media (max-width: 1440px) {
            width: 100% !important;
        }
    }

    &__main-container {
        display: flex;
        width: 100%;
        gap: 20px;
        justify-content: center;

        @media (max-width: 1200px) {
            flex-direction: column-reverse;
            width: 100%;
        }

        @media (max-width: 1440px) {
            width: 100% !important;
        }
    }

    &__first-section {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 1200px) {
            width: 100% !important;
        }
    }

    &__card-header {
        color: $text-color;
        text-align: start;
        padding: 10px 0;
        margin: 0;
        font-size: 28px !important;

        @media (max-width: 1440px) {
            font-size: 24px !important;
        }
    }

    &__customer-info {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
        color: $text-color;

        ul,
        p {
            margin: 0;
        }

        #{$self}__customer,
        #{$self}__address {
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        #{$self}__customer {
            padding-bottom: 15px;
            border-bottom: 1px solid $border-active;
        }

        #{$self}__customer-number {
            font-weight: 500;
        }
    }

    &__device-logs {
        display: flex;

        &--loader {
            height: 150px;
        }

        &--expand-btn {
            background: transparent;
            border-color: transparent;
            width: 16px;
            height: auto;
            color: $light-gray;
            cursor: pointer;
            margin-left: auto;

            span {
                font-family: 'fontAwesome';
            }
        }

        &--filters {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;
            margin: 0 0 32px 0;
            width: 100%;
            padding: 0 20px;

            select {
                width: 180px;
            }
        }

        &--device-number {
            margin: 20px;
        }

        &--device-number,
        &--log-owner {
            width: fit-content;
            color: $text-color;
            background-color: $background-gray;
            border-radius: 4px;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            padding: 2px 16px;
        }

        &--log-timestamp {
            color: #747474;
            margin-bottom: 0.5rem;
        }

        &--change-list {
            margin-bottom: 16px;
            list-style: disc;
            text-align: start;
        }

        &--sublist {
            p {
                margin-bottom: 5px !important;
            }
        }

        &--event {
            text-align: start;
        }

        &--logs-container {
            max-height: 810px;
            overflow-y: auto;
            padding-left: 20px;
        }

        &--log-header {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-bottom: 5px;

            p {
                margin: 0;
            }
        }

        &--date-range {
            display: flex;
            align-items: flex-end;

            input {
                width: 140px;
                border: none;
                border-bottom: 1px solid $border-color;
                color: $text-color;
                background-color: transparent;
                margin: 0;

                &:disabled {
                    border-bottom: 1px solid $border-disabled;
                    color: $border-disabled;
                }

                &:active {
                    border-bottom: 1px solid $border-active;
                }

                &:focus {
                    border-bottom: 1px solid $border-active;
                    outline: none;
                }
            }

            p {
                color: $text-color;
                border-bottom: 1px solid $border-color;
                display: flex;
                justify-content: center;
                margin: 0;
                padding: 0 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__app-view-header {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &--selector {
            display: flex;
            gap: 15px;
            align-items: center;
        }

        select {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 20px;
            line-height: 20px;
        }
    }

    &__iframe-wrap {
        width: 50%;
        height: 1000px;
        background-color: white;
        position: relative;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--title-and-link {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 20px;
            padding-bottom: 25px;
            border-bottom: 1px solid $border-active;

            i {
                margin-left: 10px;
            }

            a {
                text-decoration: none;
                color: $primary-orange;

                @media (max-width: 1440px) {
                    font-size: 14px;
                }
            }
        }

        &--links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            min-height: 74px;
        }

        &--iframe-web-app {
            width: 80%;
            margin: 10px 0;
            height: 800px;
            border: 8px solid $dark-border;
            border-radius: 30px;

            @media (max-width: 1200px) {
                width: 80% !important;
            }
        }

        &--notch-container {
            position: absolute;
            top: 10%;
            width: 100%;
            height: 15px;

            &--notch {
                width: 100px;
                height: 15px;
                margin: 0 auto;
                background-color: $dark-border;
                border-bottom-left-radius: 2vh;
                border-bottom-right-radius: 2vh;
            }
        }
    }

    &__delete-facility {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }
}
