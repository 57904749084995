.retailer-api {
    $self: &;

    background-color: $white;
    padding: 40px;
    color: $text-color;
    overflow: auto;

    &__description {
        padding: 10px 0;
    }

    &__code {
        background-color: $code-background;
        border-radius: 10px;
        padding: 5px 20px;
        font-family: monospace;
        font-size: 12px;
        letter-spacing: 1px;
        color: $text-color;
    }

    &__subtitle {
        font-weight: 600;
        letter-spacing: 1px;
        color: $primary-orange;
    }

    &__difficulty {
        &--level {
            display: flex;
            width: 100%;
            gap: 5px;
            justify-content: center;

            span {
                margin-bottom: 5px;
                display: block;
                background-color: $primary-orange;
                width: 12px;
                height: 12px;
                border-radius: 12px;
            }
        }
    }

    .table {
        table-layout: fixed;

        td {
            vertical-align: middle;
        }
    }

    &__customer-settings {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__nodejs {
        p {
            margin-bottom: 10px;
        }
    }
}
