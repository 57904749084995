.button {
    $self: &;
    background: $button;
    color: $white;
    padding: 10px 18px;
    width: auto;

    &:disabled {
        background-color: $button-disabled;

        &:hover {
            background-color: $button-disabled;
            cursor: default;
        }
    }

    &:hover {
        background: $button-hover;
    }

    &:active {
        background: $button-active;
    }

    &:focus {
        outline: 2px solid $button;
    }

    &__tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: $light-gray;
        color: $white;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;

        position: absolute;
        left: 110%;
        z-index: 1;
    }

    &__left {
        flex-direction: row-reverse;
    }

    &--secondary {
        background-color: transparent;
        color: $primary-orange;
        padding: 0;
        font-weight: 500;
        font-size: 20px;

        &:hover {
            background-color: transparent;
        }
    }
}
