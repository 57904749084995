.api-compatibility {
    $self: &;

    padding: 25px 20px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__search-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__table-wrapper {
        width: 100%;
        overflow-x: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        #{$self}__search {
            margin-bottom: 16px;
            width: 250px;
        }
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }

    &__device-type {
        padding: 4px;
        border-radius: 20px;
        font-size: 12px !important;

        &--ev {
            background-color: $lost;
        }

        &--cs {
            background-color: $pending;
        }

        &--hvac {
            background-color: $backoff;
        }

        &--bat {
            background-color: $active;
        }

        &--si {
            background-color: $active;
        }

        &--sm {
            background-color: $deleted;
        }
    }

    &__not-found {
        td {
            padding: 16px;
        }
    }

    &__models {
        box-shadow: 0px 0px 4px 1px $card-shadow;
        padding: 15px 20px;
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;

        &--model {
            padding: 4px 20px;
            border-radius: 20px;
            font-size: 12px !important;
            background-color: $light-gray;
            color: $white;
        }

        &--expanded {
            animation: slideDown 1.2s ease 0s 1 normal;
        }

        &--collapsed {
            animation: slideUp 1.2s ease 0s 1 normal;
        }

        &--open {
            transform: rotate(180deg);
        }
    }
}

@keyframes slideDown {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: auto;
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        height: auto;
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
}
