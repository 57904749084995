.key-management {
    $self: &;

    &__description {
        margin-bottom: 40px;
    }

    &__form {
        width: 100%;
        margin: 40px 0;
        display: flex;
        gap: 30px;
        justify-content: flex-start;
        align-items: flex-end;

        &--column {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__loader {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }

    &__keys-table {
        td {
            vertical-align: middle;
        }

        #{$self}__revoke-btn {
            font-family: 'Helvetica Neue', 'fontAwesome';
        }
    }

    &__no-keys-message {
        color: crimson;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 40px;
    }

    &__subtitle {
        font-size: 24px;
        margin: 20px 0 10px;
    }

    &__keys-wrapper {
        margin: 0 50px;
    }

    &__copy-key {
        width: 100%;
        padding: 30px;
        border: 1px solid $primary-orange;

        &:focus {
            outline: none;
        }
    }

    &__tooltip {
        width: 250px;
        margin-right: 20px;
        background-color: $button;
        color: $white;
        text-align: center;
        padding: 5px;
        position: absolute;
        top: 50%;
        transform: translate(-90%, -50%);
        z-index: 999;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            border: 10px solid transparent;
            border-right: 0;
            border-left: 20px solid $button;
            transform: translate(100%, -50%);
        }
    }

    &__copy-key-actions {
        position: relative;
    }
}
