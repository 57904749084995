.navbar {
    $self: &;

    background-color: $header-color;
    height: 91px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;

    &__nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    &__logo {
        display: flex;
        flex-direction: row;
        height: 80px;
        align-items: center;
        max-width: 500px;

        &--link {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: 20px;
                letter-spacing: 10px;
                color: $white;
            }

            &:hover {
                text-decoration: none;
            }

            img {
                padding: 0 10px;
                max-width: 220px;
            }
        }
    }

    &__dropdown-toggle {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__profile-image {
        background-color: $primary-orange;
        border-radius: 50%;
        height: 35px;
        width: 35px;
    }

    &__profile-name {
        position: relative;
        color: $white;
        display: none;

        @media (min-width: 756px) {
            display: inline;
        }
    }

    &__logout-button {
        background-color: transparent;
        border: none;
        font-size: 16px;
        line-height: 16px;
        margin-left: 8px;
    }

    &__nav-item {
        &--arrow-dropdown {
            width: 16px;
        }
    }

    .dropdown-menu {
        margin-top: 10px;
        right: 0;

        .dropdown-item:hover {
            background-color: transparent;
        }
    }

    .dropdown.no-arrow .dropdown-toggle::after {
        display: none;
    }
}
