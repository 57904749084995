.autocomplete {
    $self: &;
    width: 100%;
    position: relative;

    &__input {
        width: 100%;
    }

    &__search-result-list {
        position: absolute;
        width: 100%;
        z-index: 9;
        background-color: $white;
        list-style: none;
        margin: 0;
        border: 1px solid $light-gray;
        border-top: none;
        max-height: 350px;
        overflow: auto;
        font-size: 14px;

        li {
            padding: 10px;
            color: $text-color;
            cursor: pointer;
            transition: color, background-color, 0.3s ease-in-out;

            &:hover {
                background-color: $background-gray;
                color: $text-color;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--item-active {
            background-color: $background-gray;
            color: $text-color;
        }
    }

    &__event-bubbling-mask {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &--bubbled {
        z-index: 9999;
    }
}
