.vbatt-devices {
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-weight: bold;
        color: #333;
        margin-bottom: 20px;
    }

    &__filters {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;

        &--inputs {
            padding: 8px 12px;
            border: 1px solid $border-color;
            border-radius: 5px;
            width: 100%;
            max-width: 200px;
            font-size: 14px;
        }
    }

    &__content {
        background: $white;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.1);

        &--title {
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;

        thead {
            background-color: $tabs-background;
            color: $white;
        }

        th {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid $white;
            color: $text-color;
        }
        td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid $white;
        }

        td {
            cursor: pointer;
        }

        tbody tr:hover {
            background: rgba(0, 123, 255, 0.1);
        }
    }
}

.critical {
    color: $critical-state;
}

.warning {
    color: $warning-state;
    font-weight: bold;
}

.healthy {
    color: $healthy-state;
    font-weight: bold;
}

.no-vbatts-found {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
