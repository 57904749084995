.overview {
    $self: &;
    height: calc(100% - 91px);

    &__wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 15px;

        @media (max-width: 1400px) {
            flex-direction: column-reverse;
        }
    }

    &__map {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1400px) {
            width: 100%;
        }
    }

    &__map-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
    }

    &__charts-wrapper {
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 1400px) {
            width: 100%;
        }
    }

    &__over-time {
        height: calc(100% - 400px);
    }

    &__over-time-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        width: 100%;

        &--download-btn {
            background: none;
            border: none;

            a {
                color: $text-color;
            }
        }
    }

    &__number {
        height: 400px;
    }

    &__stats {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 60px;

        &--table {
            td,
            th {
                padding: 0 15px;
                text-align: center;
            }

            th {
                font-size: 32px;
                color: $primary-orange;
            }
        }

        &--no-margin {
            margin-left: 0;
            padding-bottom: 10px;
        }

        @media (max-width: 1600px) {
            margin: 30px 0 0;
        }
    }

    &__total-devices {
        padding-top: 10px;
        border-top: 1px solid $tab-color;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin: 0 10px;
            font-weight: 700;
            color: $primary-orange;
            font-size: 20px;
        }
    }

    .gm-ui-hover-effect {
        outline: none;
        top: -4px;
    }

    &__chart-loader {
        height: 100%;
    }
}

#chart-body {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}
