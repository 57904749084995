.devices-page-modal {
    $self: &;

    &__link {
        color: black;
        text-decoration: none;

        &:hover {
            transform: scale(1.01);
            transition: transform 0.25s ease-in;
            color: black;

            #{$self}__link--customer {
                color: $primary-orange !important;
            }
        }
    }

    &__logs-container {
        animation: myAnim 0.6s ease 0s 1 alternate forwards;
        max-height: 500px;
        overflow: auto;
        width: 100%;
        list-style: circle;
    }

    @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateY(150px);
        }

        30% {
            opacity: 0;
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
