.table {
    $self: &;
    width: 100%;
    border-collapse: collapse;

    td {
        padding: 20px;
        border: 1px solid $border-color;
        font-weight: 400;
    }

    th {
        letter-spacing: 0.5px;
        padding: 15px;
        border: 1px solid $primary-orange !important;
    }
}
