.customer-lookup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;

    &__search-page {
        min-height: 90vh;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    &__search-box {
        border-top: 6px solid $primary-orange;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
        background-color: white;
        padding: 50px 0;
        width: 800px;

        &--form {
            gap: 20px;
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            position: relative;
        }
    }

    &__button {
        width: 410px;
    }

    &__filter {
        display: flex;
        align-items: center;
        gap: 15px;

        label {
            margin-bottom: 0 !important;
            color: $text-color;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__criteria {
        margin-bottom: 10px;
    }

    &__search-error-message {
        margin: 15px 0;
        color: crimson;
        text-align: center;
        width: 100%;
    }

    &__actions {
        width: 100%;
        display: flex;
        gap: 30px;
        justify-content: center;
    }

    &__open-modal-link {
        background: none;
        border: none;
        color: $primary-orange;
        font-size: 18px;

        .add-btn {
            color: $black;
            margin-right: 10px;
        }
    }
}
