.tabs {
    $self: &;

    padding: 0px;
    background-color: $tabs-background;
    border-top: 4px solid $light-gray;
    display: block;

    &__tab-nav {
        margin: 0;
        padding: 0;
        display: flex;
    }

    &__tab-content {
        background-color: $white;
        padding: 20px;
        overflow: auto;
    }
}
