.device-alerts {
    margin: 0 auto;
    padding: 20px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__back-button {
        background-color: $primary-orange;
        color: $white;
        border: none;
        padding: 8px 14px;
        border-radius: 6px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $dark-orange;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__graphs {
        display: flex;
        flex-shrink: 1;
        gap: 20px;
        width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__summary {
        width: 40%;
        background-color: $background-white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__distribution {
        width: 60%;
        background-color: $background-white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__section-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__table-container {
        border-radius: 8px;
        box-shadow: 0 -2px 5px $card-shadow, 0 2px 5px $card-shadow;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: auto;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 12px;
            text-align: left;
        }

        th {
            background: $tabs-background;
            position: sticky;
            top: 0;
            z-index: 1;
        }

        tbody tr:hover {
            background: $background-gray;
        }
    }

    &--critical {
        color: $critical-red;
    }

    &--error {
        color: $error-red;
    }

    &--warning {
        color: $warning-yellow;
    }

    &__alert-type {
        font-weight: bold;
        padding: 5px 8px;
        border-radius: 4px;
    }
}

@media (max-width: 1200px), screen and (orientation: portrait) {
    .device-alerts__graphs {
        grid-template-columns: 1fr;
    }
}

.no-alerts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    text-align: center;
    gap: 16px;
    background: $background-white;
    border-radius: 8px;
    box-shadow: 0 2px 8px $card-shadow;
    padding: 24px;
}
