.faq {
    $self: &;

    background-color: white;
    padding: 50px 40px;
    min-height: 80vh;

    &__content {
        margin-top: 50px;
        width: 100%;
        display: flex;
        gap: 30px;

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }

    &__categories-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &__categories {
        display: flex;
        flex-direction: column;
        border: 1px solid $sidebar-border;
        color: $text-color;
        width: 330px;
        font-weight: 400;

        button {
            padding: 15px;
            background: none;
            text-align: start;
        }

        &--active {
            color: $primary-orange;
            margin-left: -1px;
            border-left: 3px solid $primary-orange;
        }

        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    &__change-language {
        padding-top: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;

        &--lang-icon {
            color: $text-color;
            margin-right: 10px;
            font-size: 20px;

            &:focus {
                outline: none;
            }
        }
    }

    &__languages {
        border: none;
        color: $text-color;
        background: none;
        width: 105px;
        font-weight: 500;
    }

    &__questions-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__search {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 40px;
        gap: 20px;

        &--input {
            width: 80%;
        }

        &--button {
            width: 250px;
            padding: 5px;

            @media (max-width: 1150px) {
                width: 150px;
            }
        }
    }

    &__brand-specific {
        display: flex;
        flex-direction: column;
        margin-top: 50px;

        &--back-link {
            color: $primary-orange;
            font-size: 16px;
            background: none;
            border: none;
            width: 100px;
            text-align: start;
            padding: 0;

            &:hover {
                background-color: transparent;
                font-weight: 700;
            }
        }

        &--category-brand {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 24px;
            color: $text-color;
        }
    }

    &__questions {
        margin-top: 40px;
        padding: 20px;
        border: 1px solid $sidebar-border;
        display: flex;
        flex-direction: column;
        gap: 25px;

        &--brands {
            justify-self: center;
            width: 100%;
            display: grid;
            grid-auto-rows: 150px;
            grid-gap: 20px;
            grid-template-columns: repeat(6, 1fr);

            @media (max-width: 1150px) {
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
            }
        }

        &--device {
            display: flex;
            border: 1px solid $sidebar-border;

            &:hover {
                cursor: pointer;
            }
        }

        &--brand-logo {
            width: 100%;
            height: 50px;
            margin-bottom: 6px;
            object-fit: contain;
        }

        &--item-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin: auto;

            h1 {
                margin-top: 10px;
                font-size: 16px;
                color: $text-color;
            }
        }
    }

    .vehicles {
        border: none;
        padding: 0;
    }

    &__no-questions {
        margin: 0;
    }

    &__contact {
        width: 100%;
        border: 1px solid $sidebar-border;
        margin-top: 40px;
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &--text {
            text-align: center;
            width: 60%;
        }

        &--email {
            color: $primary-orange;
        }
    }

    &__question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &--opened-question {
            color: $primary-orange !important;
        }

        &--down-arrow,
        &--opened-question {
            color: $text-color;
            font-size: 24px;
        }

        &--hide-answer {
            max-height: 0;
        }

        &--answer {
            max-height: 0;
        }

        &--expand {
            margin: 0 40px 20px;
            font-weight: 400;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 2000px;
            transition: max-height ease-in-out 1.5s;
        }

        &--show-answer {
            max-height: 2000px;
            transition: max-height ease-in-out 1.5s;
        }

        &--show-answer {
            p,
            ul {
                margin-bottom: 0;
            }
        }

        &--expand-question {
            color: $primary-orange;
            font-weight: 500;
            margin-bottom: 15px;
        }

        &--expand-btn {
            width: auto !important;
            transition: all 0.35s ease-in-out;
        }
    }
}
