.announcement {
    $self: &;

    &__title {
        border-bottom: 1px solid gray;
        margin-bottom: 20px !important;
    }

    &__block {
        padding: 25px 0;
        border-bottom: 1px solid gray;

        &:last-child {
            border-bottom: none;
        }
    }

    &__description {
        margin-bottom: 20px;
    }

    &__scope {
        font-size: 12px;
        padding: 5px 10px;
        background-color: gainsboro;
        border-radius: 30px;
        margin-right: 5px;
    }
}
