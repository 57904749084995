.card-wrapper {
    border-top: 6px solid $border-active;
    border-radius: 0;
    background-color: $white;
    padding: 15px;
    box-shadow: 0px 0px 10px 1px $card-shadow;

    &__title {
        padding: 10px 0;
        margin: 0 !important;
        margin: 0 !important;
    }

    &__expand-btn {
        background: transparent;
        border-color: transparent;
        width: 16px;
        height: auto;
        color: $light-gray;
        cursor: pointer;
        margin-left: auto;

        span {
            font-family: 'fontAwesome';
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        background-color: $white;
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        width: 100% !important;
    }
}
