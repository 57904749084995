.charge-sessions {
    $self: &;

    &__plots {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 50px;
        margin-top: 30px;

        h4 {
            margin-top: 20px;
        }

        &--chart {
            padding: 0 20px 20px;
            border: 1px solid $sidebar-border;
            width: 100%;
        }
    }
}
