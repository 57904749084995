$base-font-size: 16px;
$heading-font-size: 24px;
$subheading-font-size: 18px;
$paragraph-font-size: 16px;
$small-font-size: 14px;

* {
    font-family: 'Helvetica Neue', sans-serif;
}

body {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: $base-font-size;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

h1 {
    font-size: $heading-font-size * 2;
}

h2 {
    font-size: $heading-font-size * 1.75;
}

h3 {
    font-size: $heading-font-size * 1.5;
}

h4 {
    font-size: $heading-font-size * 1.25;
}

h5 {
    font-size: $heading-font-size;
}

h6 {
    font-size: $subheading-font-size;
}

p,
.medium {
    font-weight: 500;
    font-size: $paragraph-font-size;
}

small {
    font-size: $small-font-size;
}
