.devices-table {
    $self: &;
    min-height: 800px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;

    p {
        font-size: 14px;
    }

    &__wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__table-wrapper {
        width: 100%;
        overflow-x: auto;
        padding: 20px;
    }

    &__data {
        width: 100%;
        text-align: center;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

        &--header {
            background: $tabs-background;

            th {
                text-align: center;
                padding: 12px;
                font-size: 14px;
                width: auto;
            }

            &-cell {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                width: 100%;
            }
        }

        &--row {
            animation: fadeAnimation 1s ease 0s 1 normal;
            border-bottom: 1px solid $tabs-background;

            &:hover {
                cursor: pointer;
            }

            &:last-child {
                border: none;
            }

            td {
                padding: 20px 12px;
                font-size: 14px;
                width: auto;

                .fa-check {
                    color: $spo-on;
                }

                .fa-x {
                    color: $spo-off;
                }
            }

            i {
                font-size: 16px;
            }
        }

        &--state {
            padding: 4px;
            border-radius: 20px;
            font-size: 12px !important;

            &--lost {
                background-color: $lost;
            }

            &--pending {
                background-color: $pending;
            }

            &--backoff {
                background-color: $backoff;
            }

            &--active {
                background-color: $active;
            }

            &--connected {
                background-color: $active;
            }

            &--deleted {
                background-color: $deleted;
            }
        }

        &--manufacturer {
            height: auto;
            padding: 5px;

            &--logo {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }

        &--customer-view-link {
            color: $text-color;
            padding: 0 12px;

            &:hover {
                color: $button-hover;
            }
        }

        @keyframes fadeAnimation {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(32px, auto);
        width: 100%;
        gap: 20px;
        padding: 20px;

        &--actions {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
        }

        &--button {
            font-size: 14px;

            &--download {
                font-size: 14px;
                width: 100%;

                .fa-download {
                    margin-left: 5px;
                }
            }
        }
    }

    hr {
        width: 100%;
        background: $text-color;
    }

    &__no-devices-message {
        padding: 30px 0 !important;
    }
}
