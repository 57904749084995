.switch {
    $self: &;

    display: inline-block;
    position: relative;
    width: 250px;
    margin-top: 3px;
    padding: 4px;
    border-radius: 30px;
    background: $button;

    &__input {
        display: none;
    }

    &__label {
        width: 50%;
        font-size: 12px;
        line-height: 30px;
        color: $white;
        text-align: center;
        z-index: 10;
        cursor: pointer;
        position: inherit;
        transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &__toggle {
        height: 30px;
        width: 50%;
        font-size: 12px;
        line-height: 30px;
        background-color: $white;
        border-radius: 30px;
        left: 5px;
        cursor: pointer;
        position: absolute;
        transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
}
.switch__input:checked + .switch__label {
    color: $text-color;
}

.switch__input--new:checked ~ .switch__toggle {
    left: 120px;
    color: $white;
}
