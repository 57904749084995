.create-facility {
    &__success {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
    }

    &__success-message {
        width: 90%;
        text-align: center;
        color: $primary-orange;
    }

    &__copy-link {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__input-copy {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: gainsboro;
        padding: 5px;
        border-radius: 10px;
        letter-spacing: 1px;
        font-family: ui-monospace, monospace;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        width: 25px;
        height: 25px;
        border: none;
        padding: 15px 0;
    }

    &__link {
        text-align: start;
        margin-left: 10px;
        word-wrap: break-word;
    }

    &__error-message {
        margin: 0;
        padding-top: 20px;
        color: crimson;
    }
}
