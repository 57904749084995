.vbatt-device {
    &__grid-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1.5rem;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        display: flex;
        flex-direction: column;

        &-title {
            margin-top: 0;
            margin-bottom: 0.75rem;
            font-size: 1.25rem;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 0.5rem;
            color: $black;
        }

        &-text {
            margin: 0.3rem 0;
            font-size: 0.95rem;
            color: $black;
        }
    }

    &__label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-size: 0.9rem;
        color: $black;
    }

    &__select {
        padding: 0.4rem;
        font-size: 0.9rem;
        outline: none;
        max-width: 200px;
        margin-bottom: 1rem;
    }

    &__btn {
        display: inline-block;
        margin-top: 1rem;
        background-color: $button;
        color: $white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
        font-size: 0.9rem;
        transition: background-color 0.2s ease;

        &:hover {
            color: $white;
            background-color: $button-hover;
        }
    }

    &__markets {
        margin-top: 1rem;

        &-title {
            font-size: 1rem;
            margin: 0 0 0.5rem 0;
            padding-bottom: 0.25rem;
            color: $black;
        }

        &-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 0.5rem;

            th,
            td {
                border: 1px solid #ddd;
                padding: 0.5rem;
                text-align: center;
            }

            th {
                font-weight: bold;
            }
        }
    }

    &__measurements-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 0.5rem;

        th,
        td {
            padding: 0.6rem 1rem;
        }

        th {
            font-weight: bold;
            background-color: $white;
            min-width: 200px;
        }

        td {
            min-width: 250px;
        }

        tr:last-child td {
            border-bottom: none;
        }
    }

    &__events-list {
        margin-top: 1rem;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid $white;
        padding: 0.5rem;

        &--single {
            .battery-device__event-item {
                margin-bottom: 0;
                padding: 0.3rem 0.4rem 0.3rem 0.6rem;
            }
        }
    }

    &__event-item {
        margin-bottom: 0.5rem;
        border-left: 3px solid $primary-orange;
        padding: 0.4rem 0.5rem 0.4rem 0.8rem;
        background-color: #fafafa;

        .battery-device__event-title {
            margin: 0;
            font-weight: bold;
            color: #333;
            font-size: 0.9rem;
        }
    }
}

.status-connected {
    color: $dark-green;
    font-weight: 600;

    i {
        margin-right: 5px;
    }
}

.status-disconnected {
    color: $dark-red;
    font-weight: 600;

    i {
        margin-right: 5px;
    }
}

.measurements-container {
    width: 100%;
}

.measurement-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
    font-weight: bold;
    background-color: $tabs-background;
    border-radius: 6px;
    transition: background-color 0.3s;
}

.measurement-header:hover {
    background-color: $background-gray;
}

.measurement-content {
    padding: 0.5rem 1rem;
}

.measurement-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.5rem;
}

.measurement-table td {
    border: 1px solid $sidebar-border;
    padding: 0.6rem;
    text-align: left;
}

.measurement-table pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.9rem;
    background-color: $background-white;
    padding: 0.5rem;
    border-radius: 4px;
}
