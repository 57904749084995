.info-tooltip {
    $self: &;

    &__icon {
        border: 1px solid $light-gray;
        border-radius: 50%;
        padding: 3px;
        width: 16px;
        height: 16px;
        display: flex;

        &:hover {
            background-color: $background-gray;
            border-color: $background-gray;
        }

        img {
            width: 100%;
            margin: 0;
        }

        @media (min-width: 500px) {
            width: 18px;
            height: 18px;
        }

        span {
            background-color: $light-gray;
        }
    }

    &-content {
        background-color: $header-color;
        min-width: 100px;
        max-width: 200px;
        opacity: 0.95;
        box-shadow: 10px 10px 7px -6px rgba(0, 0, 0, 0.53);
        border: none;
        border-radius: 10px;
        text-align: center;
        margin: 5px 20px 0 10px;
        padding: 5px 12px;
        color: white;
        font-size: 14px;
    }
}
