.support {
    &__status-panel {
        background-color: $white;
    }

    &__third-party-info {
        padding: 10px;
    }

    &__license-plate {
        padding: 20px;
        color: $text-color;
        background-color: $white;
    }

    &__iframe-status {
        width: 100%;
        min-height: 120vh;
        overflow-x: hidden;
    }

    &__status-page-wrapper {
        overflow: auto;
    }
}
