.customer-web-view {
    $self: &;

    &__img {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
            // transform: scale(0.75);

            @media (max-width: 1240px) {
                transform: scale(0.55);
            }

            @media (max-width: 768px) {
                transform: scale(0.4);
            }
        }

        @media (max-width: 1240px) {
            padding: 0;
        }
    }

    &__custom-title {
        display: flex;
        gap: 24px;
        align-items: flex-start;

        & > button {
            display: flex;
            align-items: center;
        }

        &--reset {
            display: flex;
            gap: 4px;

            .info-tooltip__icon {
                width: 16px;
                height: 16px;
            }
        }

        &--edit-mode {
            flex-direction: column;
            gap: 4px;
        }
    }

    &__reset-button {
        font-size: 14px;
    }

    &__settings {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 10px;
        max-width: 1200px;
        border-bottom: 1px;

        &__buttons {
            display: flex;
            margin-top: 20px;
            gap: 20px;
        }
    }

    &__information-section {
        display: flex;
        flex-direction: column;
        padding: 48px 0;
        width: 100%;

        &--small-padding {
            padding: 16px 0;
        }

        &--columns {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: minmax(32px, auto);
            row-gap: 40px;
            margin-bottom: 54px;
            margin-top: 16px;
        }
    }

    &__info-field {
        $self: &;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 8px;

        &--label {
            color: $primary-orange;
            font-size: 14px;
        }

        &--value-wrapper {
            display: flex;
            justify-content: space-between;
            padding-right: 24px;

            #{ $self }__value {
                color: $text-color;
                font-size: 16px;
            }
        }

        &--color {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;

            span {
                margin-bottom: 5px;
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: 1px solid #a3a3a3;
            }
        }

        &--select {
            height: 32px;
        }

        &--tooltip {
            display: flex;
            gap: 8px;
        }
    }

    &__save-settings {
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 10px;

        &--cancel {
            border: 1px solid $primary-orange;
            padding: 9px 18px;
            font-size: inherit;
        }

        &--reset {
            font-size: 14px;
            text-decoration: underline;
        }
    }

    &__documents {
        $self: &;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 16px 0 64px 0;

        &--document-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 30px;
            padding-bottom: 8px;
            width: 100%;
            border-bottom: 1px solid $text-color;
        }

        &--description {
            display: flex;
            height: 40px;
            gap: 8px;
            align-items: flex-end;

            &--title {
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &--upload {
            display: flex;
            height: 40px;
            gap: 8px;
            align-items: flex-end;
            cursor: pointer;
            padding-bottom: 8px;
            width: 100%;
            border-bottom: 1px solid $text-color;

            &--label {
                width: 100%;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__warning {
        font-size: 14px;
        color: $dark-text;
        margin-bottom: 8px;

        i {
            color: $primary-orange;
        }
    }
}

.ColorPicker-MuiInput-underline:before {
    width: 100%;
    border-bottom: 1px solid $light-gray !important;
}

.ColorPicker-MuiInput-underline:after {
    border-bottom: 1px solid $primary-orange !important;
}

.ColorPicker-MuiFormControl-root,
.makeStyles-root-1 {
    width: 100% !important;
}

.ColorPicker-MuiInput-root input {
    width: 100%;
    height: 32px;
}

.ColorPicker-MuiBox-root,
.ColorPicker-MuiPaper-root {
    width: 320px;
}
