.batteries {
    $self: &;

    &__wrapper {
        width: 100%;
        padding: 15px 30px;
        border-top: 6px solid $tabs-border;
    }

    &__title {
        gap: 15px;
        padding: 15px 0;

        h1 {
            margin: 0;
        }

        button {
            margin: 0;
        }
    }

    &__table-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--create-battery {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__table {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

        &--header {
            background: $tabs-background;

            th {
                text-align: center;
                padding: 12px;
                font-size: 12px;
                width: auto;
            }
        }

        &--row {
            animation: fadeAnimation 1s ease 0s 1 normal;
            border-bottom: 1px solid $tabs-background;

            &:hover {
                cursor: pointer;
                background-color: $tabs-background;
            }

            &:last-child {
                border: none;
            }

            td {
                padding: 20px 12px;
                font-size: 14px;
                width: auto;
            }
        }

        #{$self}__name {
            font-weight: bold;
        }

        #{$self}__table-header-sort {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 100%;
        }
    }

    &__remove {
        width: 100px;
    }

    &__no-batteries {
        padding: 30px 0;
    }

    &__create-modal {
        &--content {
            display: flex;
            gap: 80px;
            width: 100%;
            padding: 20px;

            &-column {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                    margin-top: 15px;
                }
            }
        }

        &--error {
            width: 100%;
            padding: 10px 0;
            color: crimson;
            text-align: center;
        }
    }
}
