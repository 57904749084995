.reporting {
    &__filters {
        width: 100%;
        display: flex;
        gap: 20px;
        padding: 10px 0 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid $sidebar-border;

        &--input {
            width: 200px;
        }
    }

    &__graph-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        &--chart {
            padding-bottom: 20px;
            border: 1px solid $sidebar-border;
            width: calc(50% - 20px);

            @media (max-width: 1440px) {
                width: 100%;
            }
        }
    }

    &__title {
        padding: 20px 20px 0 20px;
        color: $primary-orange;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    &__no-data-message {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 20px;
        background-color: $code-background;
        width: fit-content;
        border-radius: 10px;
        padding: 5px 20px;
    }

    &__graphql-input {
        padding-top: 20px;
        height: 65vh;

        @media (min-width: 1560px) {
            height: 75vh;
        }

        .graphiql-container {
            border-radius: 10px;
            border: 10px solid $sidebar-border;
        }

        .topBar {
            padding: 30px 20px;
            background: $tabs-background;
        }

        .docExplorerShow {
            background: $button;
            color: $white;
            border-left: none;

            &::before {
                border-left: 2px solid $white;
                border-top: 2px solid $white;
            }
        }

        .toolbar-button {
            background: $button;
            color: $white;
            padding: 5px 15px;
            letter-spacing: 0.5px;
            box-shadow: none;
            border-radius: 0;

            &:disabled {
                background-color: $button-disabled;
            }

            &:hover {
                background: $button-hover;
            }

            &:active {
                background: $button-active;
            }

            &:focus {
                outline: 2px solid $button;
            }
        }

        .execute-button {
            box-shadow: none;
            background: $white;
            border: 1px solid $primary-orange;
            fill: $primary-orange;
        }

        .doc-explorer-title,
        .doc-explorer-back {
            overflow-x: unset;
        }

        .doc-explorer-back {
            color: $primary-orange;

            &::before {
                border-left: 2px solid $primary-orange;
                border-top: 2px solid $primary-orange;
            }
        }

        .docExplorerResizer {
            height: auto;
        }
    }

    &__savings-balance {
        text-transform: capitalize;
    }

    &__charge-sessions-explanation {
        width: 90%;
        margin: 20px 25px 0;
        text-align: justify;
        color: #474e56;
    }
}

.react-datepicker-wrapper {
    width: auto;
}
