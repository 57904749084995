.select {
    $self: &;
    width: 100%;
    padding: 5px;
    margin: 0;
    gap: 10px;
    border-bottom: 1px solid $border-color;
    border-right: none;
    border-top: none;
    border-left: none;
    color: $text-color;
    background-color: transparent;

    &:disabled {
        border-bottom: 1px solid $border-disabled;
        color: $border-disabled;
    }

    &:active {
        border-bottom: 1px solid $border-active;
    }

    &:focus {
        border-bottom: 1px solid $border-active;
        outline: none;
    }

    &__full-width {
        width: 100%;
    }
}
